<!--
* 创建人：罗兴
* 日 期：
* 描 述：假期留校申请
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">假期留校申请</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <sy-xueqi
          v-model="xqid"
          :selectdqxq="true"
          style="display: inline"
        ></sy-xueqi>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button size="small" icon="el-icon-edit" @click="detailInfo"
          >查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="jqmc" label="假期名称" width="250">
        </el-table-column>
        <el-table-column prop="xzqs" label="现住寝室" width="100">
        </el-table-column>
        <el-table-column prop="jtdzjdh" label="家庭地址及电话" width="200">
        </el-table-column>
        <el-table-column prop="lxdh" label="本人电话" width="150">
        </el-table-column>
        <el-table-column prop="lxsj" label="留校时间">
          <template slot-scope="scope">
            {{
              dateFormat2(scope.row.kssj) + '-' + dateFormat2(scope.row.jssj)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="sqly" label="申请理由"> </el-table-column>
        <el-table-column prop="fmsfty" label="是否征得父母同意">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.fmsfty === 1" type="success">是</el-tag>
            <el-tag v-if="scope.row.fmsfty === 0" type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="zt" label="审批状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 110" type="success"
              >院系审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 120" type="danger"
              >院系审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 111" type="success"
              >系学工审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 112" type="danger"
              >系学工审核拒绝</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
      <el-dialog
        :title="operation == 1 ? '新增' : '编辑'"
        :visible.sync="dialogFormVisible"
        width="40%"
        :close-on-click-modal="false"
        @close="closeDialog"
      >
        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>审批流程</div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                院系审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                学工审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="info"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="infoRules"
        >
          <el-form-item label="现住寝室" label-width="130px" prop="XZQS">
            <el-input
              v-model="info.XZQS"
              autocomplete="off"
              placeholder="现住寝室"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="家庭地址及电话"
            label-width="130px"
            prop="JTDZJDH"
          >
            <el-input
              v-model="info.JTDZJDH"
              autocomplete="off"
              placeholder="家庭地址及电话"
            ></el-input>
          </el-form-item>

          <el-form-item label="个人联系电话" label-width="130px" prop="LXDH">
            <el-input
              v-model="info.LXDH"
              autocomplete="off"
              placeholder="个人联系电话"
            ></el-input>
          </el-form-item>

          <el-form-item label="留校时间" label-width="130px" prop="SJ">
            <el-date-picker
              v-model="info.SJ"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="申请理由" label-width="130px" prop="SQLY">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入申请理由"
              v-model="info.SQLY"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="是否征得父母同意"
            label-width="130px"
            prop="FMSFTY"
          >
            <el-radio v-model="info.FMSFTY" :label="1">是</el-radio>
            <el-radio v-model="info.FMSFTY" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          v-if="
            info.SPZT == 0 ||
            info.SPZT == 200 ||
            info.SPZT == 120 ||
            info.SPZT == 112
          "
        >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import api from '../../api/jqgl'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      info: {
        ID: '',
        JRID: '',
        XH: '',
        XZQS: '',
        JTDZJDH: '',
        LXDH: '',
        KSSJ: '',
        JSSJ: '',
        SQLY: '',
        FMSFTY: 0,
        SJ: '',
        SPZT: null,
      },
      infoRules: {
        XZQS: [{ required: true, message: '请填写现住寝室', trigger: 'blur' }],
        JTDZJDH: [
          {
            required: true,
            message: '请填写家庭地址及联系电话',
            trigger: 'blur',
          },
        ],
        LXDH: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入电话号码'))
              } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                const isPhone = reg.test(value)
                value = Number(value) // 转换为数字
                if (typeof value === 'number' && !isNaN(value)) {
                  // 判断是否为数字
                  value = value.toString() // 转换成字符串
                  if (value.length < 0 || value.length > 12 || !isPhone) {
                    // 判断是否为11位手机号
                    callback(new Error('手机号码格式如:138xxxx8754'))
                  } else {
                    callback()
                  }
                } else {
                  callback(new Error('请输入电话号码'))
                }
              }
            },
          },
        ],
        SJ: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        SQLY: [{ required: true, message: '请填写申请理由', trigger: 'blur' }],
      },
      splist: [],
    }
  },
  watch: {},
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      api
        .getstulxsqpagelist({
          queryJson: JSON.stringify({ xqid: this.xqid }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        api.getSPJL({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data
          }
        })
        api.getlxsqformdata({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.info.ID = res.data.id
            this.info.JRID = res.data.jrid
            this.info.XZQS = res.data.xzqs
            this.info.JTDZJDH = res.data.jtdzjdh
            this.info.LXDH = res.data.lxdh
            this.info.KSSJ = res.data.kssj
            this.info.JSJ = res.data.jssj
            this.info.SQLY = res.data.sqly
            this.info.FMSFTY = res.data.fmsfty
            this.info.SJ = [new Date(res.data.kssj), new Date(res.data.jssj)]
            this.info.SPZT = res.data.spzt
          }
        })
        this.operation = 0
        this.dialogFormVisible = true
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.info.KSSJ = moment(this.info.SJ[0]).format('YYYY-MM-DDTHH:mm:ss')
          this.info.JSSJ = moment(this.info.SJ[1]).format('YYYY-MM-DDTHH:mm:ss')
          api.LXSQSave(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
